.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    background: transparent;
    padding: 0 20px;
    position: fixed;
    margin-top: 10px;
    top: 0;
    left: 0;
    z-index: 1000;
    box-sizing: border-box;
}

.site-logo {
    height: 70px;
}

.nav-right {
    display: flex;
    align-items: center;
}

.social-logo {
    height: 30px;
    margin-left: 20px;
}

.show-popup-btn {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

@media (max-width: 768px) {
    .navbar {
        padding: 0 10px;
    }

    .show-popup-btn {
        font-size: 14px;
        padding: 8px 16px;
    }

    .social-logo {
        height: 25px;
        margin-left: 10px;
    }
}