html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Aeonik TRIAL Regular', sans-serif;
  overflow: auto;
}

body {
  background: url('./assets/images/background.png') no-repeat center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.container {
  /* display: flex;
  flex-direction: column; */
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  /* position: relative;
  overflow: hidden; */
}

.content {
  text-align: left;
  position: relative;
  z-index: 1;
  margin-top: 100px;
  padding: 50px;
}

.headline {
  color: rgba(144, 255, 248, 1);
  font-family: 'Aeonik TRIAL Bold', sans-serif;
  font-weight: bold;
  font-size: 120px;
  text-shadow: 0px 5px 3px rgba(0, 0, 0, 0.12);
}

.description {
  color: rgba(255, 255, 255, 1);
  text-shadow: 0px 3px 1px rgba(0, 0, 0, 1);
  font-family: 'Aeonik TRIAL Regular', sans-serif;
  font-weight: Regular;
  font-size: 28px;
}

.desc-container {
  padding-top: 40px;
  max-width: 50%;
}

.rotating-text {
  text-shadow: 0px 3px 2px rgba(0, 0, 0, 1);
  margin-top: 60px;
  font-size: 38px;
  color: white;
  font-family: 'Aeonik TRIAL Bold', sans-serif;
}

.rotating-word {
  transition: color 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .headline {
    font-size: 60px;
    text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.12);
  }

  .description {
    font-size: 24px;
  }

  .rotating-text {
    font-size: 30px;
  }

  .content {
    margin-top: 100px;
  }
}