@font-face {
  font-family: 'Aeonik TRIAL Bold';
  src: url('./assets/fonts/AeonikTRIAL-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik TRIAL Regular';
  src: url('./assets/fonts/AeonikTRIAL-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Aeonik TRIAL Regular', sans-serif;
}

h2 {
  font-family: 'Aeonik TRIAL Bold', sans-serif;
}