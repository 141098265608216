.popup {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: url("../assets/images/popup.png") no-repeat center center;
    background-size: cover;
    padding: 20px;
    border-radius: 30px;
    text-align: center;
    width: 500px;
    max-width: 90%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Aeonik TRIAL Bold', sans-serif;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
}

h2 {
    margin-bottom: 10px;
    font-family: 'Aeonik TRIAL Bold', sans-serif;
    font-size: 48px;
}

hr {
    border: none;
    border-top: 5px solid #000;
    width: 250px;
    margin: 10px auto;
}

p {
    margin-bottom: 20px;
    font-size: 20px;
    font-family: 'Aeonik TRIAL Bold', sans-serif;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-group {
    display: flex;
    width: 80%;
    justify-content: space-between;
}

input {
    width: 40%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
}

button[type="submit"] {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 15px 80px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

.thank-you-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: white;
}

.thank-you-message h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: black;
}

.thank-you-message p {
    font-size: 16px;
    color: black;
}